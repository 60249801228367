import React, { useEffect } from 'react';
import * as THREE from 'three';
import './index.css'; // import your only CSS file

const App = () => {

  useEffect(() => {
    let scene, camera, renderer;
    let previousPosition = { x: 0, y: 0 };
    let speed = { x: 0, y: 0 };

    function init() {
      // Setup scene, camera and renderer
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);
      document.getElementById('three-container').appendChild(renderer.domElement);

      const cubieSize = 1;
      const offset = (3 * cubieSize) / 2;

      const colors = [
        0xff0000, // Red
        0x00ff00, // Green
        0x0000ff, // Blue
        0xffff00, // Yellow
        0xffa500, // Orange
        0xffffff  // White
      ];

      const createCubie = (x, y, z) => {
        const geometry = new THREE.BoxGeometry(cubieSize, cubieSize, cubieSize);
        const materials = [
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] }), // Front
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] }), // Back
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] }), // Top
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] }), // Bottom
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] }), // Left
          new THREE.MeshBasicMaterial({ color: colors[Math.floor(Math.random() * colors.length)] })  // Right
        ];
        const cubie = new THREE.Mesh(geometry, materials);
        cubie.position.set(x * cubieSize - offset, y * cubieSize - offset, z * cubieSize - offset);
        scene.add(cubie);
      };

      // Create cubies for the Rubik's cube
      for (let x = 0; x < 3; x++) {
        for (let y = 0; y < 3; y++) {
          for (let z = 0; z < 3; z++) {
            createCubie(x, y, z);
          }
        }
      }

      camera.position.z = 8;

      function animate() {
        requestAnimationFrame(animate);
        scene.rotation.x += speed.y;
        scene.rotation.y += speed.x;
        renderer.render(scene, camera);
      }
      animate();

      // Add raycaster for click detection
      const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();

      function onDocumentMouseClick(event) {
        event.preventDefault();
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);

        const intersects = raycaster.intersectObjects(scene.children);
        if (intersects.length > 0) {
          window.location.href = 'https://phoer20.blog';
        }
      }

      window.addEventListener('click', onDocumentMouseClick);

      function onMouseMove(event) {
        const currentMousePosition = { x: event.clientX, y: event.clientY };
        const deltaX = currentMousePosition.x - previousPosition.x;
        const deltaY = currentMousePosition.y - previousPosition.y;

        speed = {
          x: deltaX * 0.003, // Modify this multiplier to change sensitivity
          y: deltaY * 0.003
        };

        previousPosition = currentMousePosition;
      }

      function onTouchMove(event) {
        const touch = event.touches[0];
        const currentPosition = { x: touch.clientX, y: touch.clientY };
        const deltaX = currentPosition.x - previousPosition.x;
        const deltaY = currentPosition.y - previousPosition.y;

        speed = {
          x: deltaX * 0.001, // Modify this multiplier to change sensitivity
          y: deltaY * 0.001
        };

        previousPosition = currentPosition;
      }

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(window.innerWidth, window.innerHeight);
      }

      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('touchmove', onTouchMove, { passive: true });
      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('mousemove', onMouseMove);
        window.removeEventListener('touchmove', onTouchMove);
        window.removeEventListener('resize', onWindowResize);
        window.removeEventListener('click', onDocumentMouseClick);
        document.getElementById('three-container').innerHTML = ''; // Clear the 3D content when unmounting
      };
    }

    init();

  }, []);

  return (
    <div>
      <h1>Hello, React</h1>
      <p>Welcome to the page</p>
      <div id="three-container"></div>
    </div>
  );
};

export default App;

function loginWithMetaMask() {
  console.log("loginWithMetaMask triggered");
  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  if (window.ethereum) {
    // Web3 enabled browser on desktop or mobile with MetaMask extension installed
    window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(accounts => {
        console.log(`Logged in as ${accounts[0]}`);
        window.location.href = `profile.html?username=${accounts[0]}`; // Redirect to the profile page after a successful login
      })
      .catch(err => {
        console.error('MetaMask login request failed', err);
      });
  } else if (isMobile) {
    window.location.href = 'https://metamask.app.link/dapp/phoer20.blog'; // Replace YOUR_APP_URL with your app's URL
  } else {
    alert('MetaMask is not installed. Please visit metamask.io to install it.');
  }
}
